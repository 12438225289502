import React from 'react'

import { TAccount } from '@sga/shopify-payments-types'

export type TAuthContext = {
  account: TAccount
  shopAdminUrl: string
  shopGatewayUrl: string
  refreshAccount(): Promise<TAccount>
}

const context = React.createContext<TAuthContext | null>(null)

export const AuthProvider = context.Provider

export function useAuth(silent = false) {
  const auth = React.useContext(context)
  if (!auth && !silent) {
    throw new Error("Called useAuth before it's set")
  }
  return auth!
}
