import { FormProvider } from 'react-hook-form'

import { Button, Card, Collapsible, Form, SelectOption, Stack, TextContainer, Toast } from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { GoPayDefaultInstrument } from './GoPayDefaultInstrument'
import { SettingsContactCard } from './SettingsContactCard'
import { SettingsLanguage } from './SettingsLanguage'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'
import SettingsNumericId from './SettingsNumericId'
import { useState } from 'react'
import { TAccountActivationStatus } from '@sga/shopify-payments-types'

type TProps = {
  goBack: () => any
  status: TAccountActivationStatus
}
GoPayAccountSettings.segment = segment('gopay')

export function GoPayAccountSettings({ goBack, status }: TProps) {
  const [advancedCollapsibleOpen, setAdvancedCollapsibleOpen] = useState(false)

  const config = useAuth()
  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm((api, formValues) =>
    api.saveConfig(formValues),
  )

  if (status === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          <SettingsContactCard />
          <GoPayDefaultInstrument />
          <SettingsLanguage languages={getLanguages()} />
          <SettingsPaymentLabel />
          <Card.Section title={getTranslation('Pokročilé API nastavení', 'Advanced API settings')}>
            <TextContainer>
              <b>
                {getTranslation(
                  'Toto nastavení je určeno pouze pro pokročilé uživatele!',
                  'This settings should be changed only by advanced users!',
                )}
              </b>
            </TextContainer>
            <br />

            <Button onClick={() => setAdvancedCollapsibleOpen(!advancedCollapsibleOpen)}>
              {advancedCollapsibleOpen ? getTranslation('Skrýt', 'Hide') : getTranslation('Zobrazit', 'Display')}
            </Button>

            <Collapsible id="advanced-settings-collapsable" open={advancedCollapsibleOpen}>
              <br />
              <SettingsNumericId />
            </Collapsible>
          </Card.Section>
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Button onClick={goBack}>{getTranslation('Zpět', 'Go Back')}</Button>
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}

function getLanguages(): SelectOption[] {
  return [
    { value: '' as any, label: 'Automaticky' },
    { value: 'CS', label: 'Čeština' },
    { value: 'EN', label: 'Angličtina' },
    { value: 'SK', label: 'Slovenština' },
    { value: 'DE', label: 'Němčina' },
    { value: 'RU', label: 'Ruština' },
    { value: 'PL', label: 'Polština' },
    { value: 'HU', label: 'Maďarština' },
    { value: 'FR', label: 'Francouzština' },
    { value: 'RO', label: 'Rumunština' },
    { value: 'BG', label: 'Bulharština' },
    { value: 'HR', label: 'Chorvatština' },
    { value: 'IT', label: 'Italština    ' },
  ]
}
